<template>
  <div class="w-100 inputSearchContainer">
    <buscador-refugios></buscador-refugios>
  </div>
</template>

<script>

import BuscadorRefugios from './components/BuscadorRefugios.vue'


export default {
  name: 'prames-buscador',
  components: {
    BuscadorRefugios
  }
}
</script>

<style>

</style>
