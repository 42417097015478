<template>
    <div class="w-100">
        <input type="text" :placeholder="placeholder" 
        @input="buscar" v-model="query" 
        class="inputSearch w-100">
        <button @click="limpiar" id="limpiarBusqueda">
            <i class="fa fa-times"></i>
        </button>
        <transition name="slide-fade">
            <div class="w-100 pb-3 position-relative">
                <ul class="resultados" v-if="visible">
                    <li class="resultado" :key="refugio.id" v-for="(refugio) in filtrarRefugios"> 
                        <div class="imagen">
                            <img :src="`/resources/refugios/${refugio.friendlyurl}/img/1.jpg`" class="w-100"
                            :alt="refugio.nombre">
                        </div>
                        <div class="datos">
                            <h4 class="titleBuscadorRefugio"><a :href="`/${refugio.friendlyurl}/`">{{ refugio.nombre }}</a></h4>
                            <small><span v-if="refugio.paraje != '' ">{{refugio.paraje}}, </span>{{ refugio.localidad }}, {{ refugio.provincia }}</small>
                            <div class="botones">
                                <a class="btn btn-outline-info btn-sm botonFicha" :href="`/${refugio.friendlyurl}/`">Ficha</a>&nbsp;
                                <a  v-if="refugio.motor == '1'" class="btn btn-outline-info btn-sm botonReserva" :href="`/${refugio.friendlyurl}/reservar`">Reservar</a>
                            </div>
                        </div>
                    </li>
                    <!-- <li v-if="total > 5" class="col text-center masResultados">
                        <a class="btn btn-success btn-lg" href="#">Ver todos los resultados</a>
                    </li> -->
                    <li v-if="total == 0">
                        <small>No hay resultados para este termino de búsqueda</small>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

export default {
  name: "buscador-refugios",
  data()
  {
      return {
            placeholder: "Busca tu destino",
            query: '',
            refugios: [],
            total:0,
            visible: false
        }
  },
  
  mounted(){
      axios
      .get('https://api.alberguesyrefugios.com/refugios/getAll')
      .then(response => this.refugios = response.data.result )
  },
  methods:{
      buscar(){
          if (this.query.length >= 1) 
                this.visible = true;
            else
                this.visible = false;
      },
      limpiar(){
          this.query = "";
          this.visible = false;
      }
  },
  computed:{
      filtrarRefugios() {
        var data =  this.refugios.filter( (refugio) => 
        {
            var buscar = this.query.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            var localidad = refugio.localidad.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            var nombre = refugio.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            var montana = refugio.montanaCaracter.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            var valle = refugio.valleSierra.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            var sistema = refugio.sistemaMontanoso.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            var punto = refugio.puntoCaracter.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            var sendero = refugio.senderoCaracterisitico.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            var paraje = refugio.paraje.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

            var enp = _.join(refugio.enp);
            enp = enp.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        
            if(localidad.toLowerCase().includes(buscar.toLowerCase()) || 
                nombre.toLowerCase().includes(buscar.toLowerCase()) ||
                montana.toLowerCase().includes(buscar.toLowerCase()) ||
                valle.toLowerCase().includes(buscar.toLowerCase()) ||
                sistema.toLowerCase().includes(buscar.toLowerCase()) ||
                punto.toLowerCase().includes(buscar.toLowerCase()) ||
                sendero.toLowerCase().includes(buscar.toLowerCase()) ||
                enp.toLowerCase().includes(buscar.toLowerCase()) ||
                paraje.toLowerCase().includes(buscar.toLowerCase()))
                return refugio;
        }
            //(refugio.localidad.toLowerCase().includes(this.query.toLowerCase()) || refugio.nombre.toLowerCase().includes(this.query.toLowerCase()))
        );
        this.total = data.length;
        return data;
      },
      filtrados(){
          if (this.query.length >= 3){
              this.visible = true;
          }
          else{
              this.visible = false;
          }
          
      }
  }
};
</script>

